<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="9"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableVehicles.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="form-group">
            <label>
              <strong>Vehicle Category</strong>
            </label>
            <b-select
              v-model="tableVehicles.filter.vehicle_category"
              :options="filterVehicleCategory"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onCreate"
          >
            New Vehicle
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="tableVehicles"
        class="mt-2"
        :per-page="tableVehicles.perPage"
        :current-page="tableVehicles.currentPage"
        :items="tableProvider"
        :fields="tableVehicles.fields"
        :sort-by.sync="tableVehicles.sortBy"
        :sort-desc.sync="tableVehicles.sortDesc"
        :sort-direction="tableVehicles.sortDirection"
        :filter="tableVehicles.filter"
        :filter-included-fields="tableVehicles.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableVehicles.currentPage * tableVehicles.perPage - tableVehicles.perPage + (data.index + 1) }}
        </template>
        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="onEdit(row.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableVehicles.perPage"
              :options="tableVehicles.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableVehicles.currentPage"
            :total-rows="tableVehicles.totalRows"
            :per-page="tableVehicles.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-vehicle-form"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
      @ok="onValidate"
    >
      <ValidationObserver
        ref="formVehicle"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="vehicle type"
            vid="vehicle_category"
            rules="required"
          >
            <div class="form-group">
              <label for="vehicle_category">
                <strong>Vehicle Category</strong>
              </label>
              <b-form-select
                id="vehicle_category"
                v-model="vehicle.vehicle_category"
                type="text"
                :options="list.vehicleCategories"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Vehicle Category --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
            rules="required|max:100"
          >
            <div class="form-group">
              <label for="name">
                <strong>Name</strong>
              </label>
              <b-input
                id="name"
                v-model="vehicle.name"
                type="text"
                placeholder="enter name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="license plate"
            vid="license_plate"
            rules="required|max:15"
          >
            <div class="form-group">
              <label for="license_plate">
                <strong>License Plate Number</strong>
              </label>
              <b-input
                id="license_plate"
                v-model="vehicle.license_plate"
                type="text"
                placeholder="enter license plate"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="vehicle_type"
            vid="vehicle_type"
            rules="required"
          >
            <b-form-group>
              <label for="vehicle_type">
                <strong>Vehicle Type</strong>
              </label>
              <b-form-select
                id="vehicle_type"
                v-model="vehicle.vehicle_type"
                type="text"
                :options="vehicleTypes"
                :state="errors.length > 0 ? false : null"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Vehicle Type --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="vehicle.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SLOVehicle, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import helper from '@/mixins/helper'

export default {
  name: 'LogisticsOfficerVehicles',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Vehicles'
    }
  },

  mixins: [formatter, helper],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        drivers: [],
        vehicleCategories: []
      },
      vehicle: {
        id: 0,
        name: '',
        license_plate: '',
        registration_expiry: '',
        vehicle_category: null,
        vehicle_type: 'rented',
        active: 0
      },
      tableVehicles: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: '',
          vehicle_category: 'All'
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'name', sortable: true },
          { key: 'vehicle_category_name', sortable: true },
          { key: 'license_plate' },
          { key: 'active' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Vehicle' : 'New Vehicle'
    },

    filterVehicleCategory () {
      return [{ value: 'All', text: 'All' }].concat(this.list.vehicleCategories)
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {
    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableVehicles.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_vehicle_category: filter.vehicle_category,
        filter_text: filter.search
      })

      return await SLOVehicle.get(filters).then(
        ({ data }) => {
          this.tableVehicles.totalRows = data.total_rows
          return data.items
        }
      )
        .catch(() => {
          this.tableVehicles.totalRows = 0
          return []
        })
        .finally(() => {
          this.tableVehicles.busy = false
        })
    },

    async getOptions () {
      await SSharedList.getVehicleCategories().then(
        ({ data }) => {
          this.list.vehicleCategories = data.map(
            ({ id, name }) => ({
              text: name,
              value: id
            })
          )
        }
      ).catch(() => {
        this.list.vehicleCategories = []
      })
    },

    onCreate () {
      this.state.editing = false
      this.vehicle.id = 0
      this.vehicle.name = ''
      this.vehicle.license_plate = ''
      this.vehicle.registration_expiry = ''
      this.vehicle.vehicle_category = null
      this.vehicle.vehicle_type = 'rented'
      this.vehicle.active = 1
      this.$bvModal.show('modal-vehicle-form')
    },

    onEdit (item) {
      this.state.editing = true
      this.vehicle.id = item.id
      this.vehicle.name = item.name
      this.vehicle.license_plate = item.license_plate
      this.vehicle.vehicle_category = item.vehicle_category_id
      this.vehicle.vehicle_type = item.vehicle_type
      this.vehicle.active = item.active
      this.$bvModal.show('modal-vehicle-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formVehicle.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update Vehicle?' : 'Create Vehicle?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(
        resolve => {
          SLOVehicle.post(this.vehicle).then(
            ({ data }) => {
              this.$bvModal.hide('modal-vehicle-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tableVehicles.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formVehicle.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(
        resolve => {
          SLOVehicle.put(this.vehicle).then(
            ({ data }) => {
              this.$bvModal.hide('modal-vehicle-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.tableVehicles.localItems, {
                  id: data.vehicle.id
                })
                row.name = data.vehicle.name
                row.license_plate = data.vehicle.license_plate
                row.registration_expiry = data.vehicle.registration_expiry
                row.vehicle_category_name = data.vehicle.vehicle_category.name
                row.vehicle_category_id = data.vehicle.vehicle_category_id
                row.vehicle_type = data.vehicle.vehicle_type
                row.active = data.vehicle.active
                row.updated_at = data.vehicle.updated_at
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formVehicle.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
